
import Vue from "vue"
import { mapActions, mapState } from "vuex"
import EventsListTable from "./EventsListTable.vue"
import EventsCreateDialog from "./EventsCreateDialog.vue"
import EventsEditDialog from "./EventsEditDialog.vue"
import Confirm from "@/components/Confirm.vue"

export default Vue.extend({
  name: "EventsListScreen",

  components: {
    EventsListTable,
    EventsCreateDialog,
    EventsEditDialog,
    Confirm
  },

  data: () => ({
    eventsCreateDialogShown: false,
    eventsEditDialogShown: false,
    eventsEditDialogEvent: {}
  }),

  computed: {
    ...mapState("events", {
      eventsStateListIsLoading: "listIsLoading",
      eventsStateList: "list",
      eventsStateRemoveOneIsLoading: "removeOneIsLoading",
      eventsStateRemoveManyIsLoading: "removeManyIsLoading"
    }),

    loading(): boolean {
      return (
        this.eventsStateListIsLoading ||
        this.eventsStateRemoveOneIsLoading ||
        this.eventsStateRemoveManyIsLoading
      )
    }
  },

  created() {
    this.eventsActionsList()
  },

  methods: {
    ...mapActions("events", {
      eventsActionsList: "list",
      eventsActionsRemoveOne: "removeOne",
      eventsActionsRemoveMany: "removeMany"
    }),

    eventsCreateDialogOpen(): void {
      this.eventsCreateDialogShown = true
    },

    eventsEditDialogOpen(item): void {
      this.eventsEditDialogShown = true
      this.eventsEditDialogEvent = item
    },

    async eventsRemoveOne(event): Promise<void> {
      if(!await this.$refs.confirm.dangerous(
        this.$t("eventsRemoveOneConfirmation", {
          eventTitle: event.title
        })
      )) return

      try {
        await this.eventsActionsRemoveOne(event)
        this.$success(this.$t("eventsActionsRemoveOneSuccess", {
          eventTitle: event.title
        }))
      } catch(e) {
        this.$error(e.message)
      }
    },

    async eventsRemoveMany(events): Promise<void> {
      const count = events.length

      if(!await this.$refs.confirm.dangerous(
        this.$t("eventsRemoveManyConfirmation", {
          eventsCount: count
        })
      )) return

      try {
        await this.eventsActionsRemoveMany(events)
        this.$success(this.$t("eventsActionsRemoveManySuccess", {
          eventsCount: count
        }))
      } catch(e) {
        this.$error(e.message)
      }
    }
  }
})
