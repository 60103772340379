
import Vue from "vue"
import { mapActions, mapState } from "vuex"
import eventsContestantsStub
  from "./events.contestants.stub"
import EventsContestantsCreateDialogMemberSelectItem
  from "./EventsContestantsCreateDialogMemberSelectItem.vue"
import WeaponsCreateDialog
  from "@/weapons/WeaponsCreateDialog.vue"
import ClubsMembersCreateDialog
  from "@/clubs/members/ClubsMembersCreateDialog.vue"

const strIncludes = (v: string, q: string): boolean => (
  v.toLowerCase().includes(q.toLowerCase())
)

export default Vue.extend({
  name: "EventsContestantsCreateDialog",

  components: {
    EventsContestantsCreateDialogMemberSelectItem,
    WeaponsCreateDialog,
    ClubsMembersCreateDialog
  },

  props: {
    shown: { type: Boolean, default: false },
    event: { type: Object, required: true }
  },

  data() {
    return {
      visible: this.shown,
      weaponsCreateDialogShown: false,
      clubsMembersCreateDialogShown: false,
      selectedClubsMemberId: "",
      weaponsFormIndex: null,
      weaponsForm: [{ ...eventsContestantsStub }],
      weaponsFormRules: {
        weaponId: { required: true, message: "Choose a weapon" },
        calibre: { required: true, message: "Enter a calibre" }
      }
    }
  },

  computed: {
    ...mapState("clubs/members", {
      clubsMembersStateListIsLoading: "listIsLoading",
      clubsMembersStateList: "list"
    }),
    ...mapState("weapons", {
      weaponsStateListIsLoading: "listIsLoading",
      weaponsStateList: "list"
    }),
    ...mapState("events/contestants", {
      eventsContestantsStateCreateManyIsLoading: "createManyIsLoading"
    }),
    hasSelectedMember(): boolean {
      return this.selectedClubsMemberId !== ""
    }
  },

  watch: {
    visible(visible): void {
      this.$emit("update:shown", visible)
      if(!visible) return
      this.weaponsActionsList()
      this.clubsMembersActionsList()
    },
    shown(visible): void {
      this.visible = visible
    }
  },

  methods: {
    ...mapActions("clubs/members", {
      clubsMembersActionsList: "list"
    }),

    ...mapActions("weapons", {
      weaponsActionsList: "list"
    }),

    ...mapActions("events/contestants", {
      eventsContestantsActionsCreateMany: "createMany"
    }),

    addWeapon(): void {
      this.weaponsForm.push({ ...eventsContestantsStub })
    },

    removeWeapon(index): void {
      if(this.weaponsForm.length === 1) return
      this.weaponsForm.splice(index, 1)
    },

    async submit(): Promise<void> {
      const validate = this.$refs.forms.map(({ validate }) => validate())
      if(validate.includes(false)) {
        this.$error(this.$t("validationError"))
        return
      }

      const contestants = this.weaponsForm.map(({
        weaponId,
        calibre
      }) => ({
        clubMemberId: this.selectedClubsMemberId,
        eventId: this.event.id,
        weaponId,
        calibre: calibre
      }))

      try {
        await this.eventsContestantsActionsCreateMany(contestants)
        this.$success(this.$t("saveSuccess", {
          count: contestants.length
        }))
        this.close()
        this.weaponsForm = [{ ...eventsContestantsStub }]
      } catch(e) {
        this.$error(e.message)
      }
    },

    close(): void {
      this.visible = false
    },

    weaponsCreateDialogOpen(weaponsFormIndex: number): void {
      this.weaponsFormIndex = weaponsFormIndex
      this.weaponsCreateDialogShown = true
    },

    weaponCreated(weapon): void {
      this.weaponsForm[this.weaponsFormIndex].weaponId = weapon.id
    },

    clubMemberFilter(item, queryText: string): boolean {
      if(strIncludes(`${item.firstName} ${item.lastName}`, queryText)) return true
      if(!item.club) return false
      if(strIncludes(item.club.name, queryText)) return true
      if(strIncludes(item.club.shortName, queryText)) return true
      return false
    },

    clubsMembersCreateDialogOpen(): void {
      this.clubsMembersCreateDialogShown = true
    },

    memberCreated(member): void {
      this.selectedClubsMemberId = member.id
    }
  }
})
