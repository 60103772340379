
import Vue from "vue"
import { mapActions, mapState } from "vuex"
import DataGrid from "@/components/DataGrid.vue"
import EventsViewTabsContestants
  from "./EventsViewTabsContestants.vue"
import EventsViewTabsDivisions
  from "./EventsViewTabsDivisions.vue"
import EventsViewTabsResults
  from "./EventsViewTabsResults.vue"
import EventsEditDialog
  from "./EventsEditDialog.vue"
import Confirm
  from "@/components/Confirm.vue"

export default Vue.extend({
  name: "EventsViewScreen",

  components: {
    EventsViewTabsContestants,
    EventsViewTabsDivisions,
    EventsViewTabsResults,
    EventsEditDialog,
    DataGrid,
    Confirm
  },

  data: () => ({
    activeTab: 0,
    eventsEditDialogShow: false,
    printMode: "",
    deadline: ""
  }),

  computed: {
    ...mapState("events", {
      eventsStateRemoveOneIsLoading: "removeOneIsLoading",
      eventsStateSelectedIsLoading: "selectedIsLoading",
      eventsStateSelected: "selected"
    }),
    eventsIsLoading(): boolean {
      return (
        this.eventsStateSelectedIsLoading ||
        this.eventsStateRemoveOneIsLoading
      )
    }
  },

  watch: {
    "$route.params.eventId": {
      immediate: true,
      handler(id): void {
        this.eventsActionsSelect({ id })
        this.eventsContestantsActionsList({
          filter: { eventId: id }
        })
        this.eventsDivisionsActionsList({
          filter: { eventId: id }
        })
      }
    }
  },

  methods: {
    ...mapActions("events", {
      eventsActionsSelect: "select",
      eventsActionsRemoveOne: "removeOne"
    }),

    ...mapActions("events/contestants", {
      eventsContestantsActionsList: "list"
    }),

    ...mapActions("events/divisions", {
      eventsDivisionsActionsList: "list"
    }),

    eventsRangeOpenMap(): void {
      const { range } = this.eventsStateSelected
      window.open(`https://www.google.com/maps/@${range.lat},${range.lng},15z`)
    },

    eventsEditDialogOpen(): void {
      this.eventsEditDialogShow = true
    },

    async eventsRemoveOne(): Promise<void> {
      const event = this.eventsStateSelected

      if(!await this.$refs.confirm.dangerous(
        this.$t("eventsRemoveOneConfirmation", {
          eventTitle: event.title
        })
      )) return

      try {
        await this.eventsActionsRemoveOne(event)
        this.$success(this.$t("eventsActionsRemoveOneSuccess", {
          eventTitle: event.title
        }))
        this.$router.push("/")
      } catch(e) {
        this.$error(e.message)
      }
    },

    printDivisionSchedule(): void {
      this.printMode = "divisionSchedule"
      this.$print()
    },

    printDivisionStickers(): void {
      this.printMode = "divisionStickers"
      this.$print()
    },

    printResultsDeadline(): void {
      this.printMode = "resultsDeadline"
      this.deadline = this.$date(Date.now())
        .add("1", "hour")
        .format("HH:MM")

      this.$print()
    },

    printResultsFinal(): void {
      this.printMode = "resultsFinal"
      this.$print()
    }
  }
})
