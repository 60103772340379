
import { mapState, mapMutations } from "vuex"
import Avatar from "@/components/Avatar.vue"
import EventsContestantsMeasurement
  from "./EventsContestantsMeasurement.vue"
import EventsContestantsFilterDivisions
  from "./EventsContestantsFilterDivisions.vue"
import EventsContestantsFilterWeapons
  from "./EventsContestantsFilterWeapons.vue"
import EventsDivisionsLabel
  from "../divisions/EventsDivisionsLabel.vue"
import { sortResults }
  from "./events.contestants.utils"

function addStatuses(results) {
  for(const c of results) {
    const hits = c.hits.filter((hit) => Number.isInteger(hit.sum))
    if(hits.length >= 10) continue
    c.status = hits.length === 0
      ? "DNS"
      : "DNF"
  }
}

export default {
  name: "EventsContestantsResultsListTable",

  components: {
    EventsContestantsMeasurement,
    Avatar,
    EventsContestantsFilterDivisions,
    EventsContestantsFilterWeapons,
    EventsDivisionsLabel
  },

  props: {
    event: { type: Object, required: true }
  },

  data() {
    return {
      eventsContestantsResultsMeasurementShown: false,
      eventsContestantsTableFilter: {
        weaponIds: [],
        clubIds: [],
        divisionIds: []
      },
      eventsContestantsResultsSearchFilter: "",
      eventsContestantsResultsTableGroupBy: "weaponId",
      eventsContestantsResultsSelection: [],
      eventsContestantsResultsHeaders: [{
        value: "status",
        text: "Plass",
        sortable: false
      }, {
        value: "number",
        text: "Deltaker",
        sortable: false
      }, {
        value: "clubMember.club.shortName",
        text: this.$t("columnLabelClubName"),
        filter: this.clubFilter,
        sortable: false
      }, {
        value: "weaponId",
        text: this.$t("columnLabelWeapon"),
        filter: this.weaponFilter,
        sortable: false
      },
      // {
      //   value: "divisionId",
      //   text: "Standplassliste",
      //   filter: this.divisionFilter,
      //   sortable: false
      // },
      {
        value: "hits",
        text: "Treff",
        sortable: false
      }, {
        value: "total",
        text: "Totalt",
        sortable: false,
        align: "center"
      }, {
        value: "measurement",
        text: "Mål",
        sortable: false,
        align: "center",
        width: 80
      }]
    }
  },

  computed: {
    ...mapState("events/contestants", {
      eventsContestantsStateListIsLoading: "listIsLoading",
      eventsContestantsStateList: "list"
    }),

    isntGrouped(): boolean {
      return this.eventsContestantsResultsTableGroupBy === null
    },

    results() {
      const all = [ ...this.eventsContestantsStateList ]

      if(this.isntGrouped) {
        const sorted = sortResults(all)
        addStatuses(sorted)
        return sorted
      }

      const grouped = all.reduce((groups, c) => {
        const groupBy = c[this.eventsContestantsResultsTableGroupBy]
        if(!groups[groupBy]) groups[groupBy] = []
        groups[groupBy].push(c)
        return groups
      }, {})

      const groupSorted = []

      for(const group in grouped) {
        const items = grouped[group]
        if(items) {
          const sorted = sortResults(items)
          addStatuses(sorted)
          groupSorted.push(...sorted)
        }
      }

      return groupSorted
    }
  },

  methods: {
    ...mapMutations("events/contestants", {
      eventsContestantsMutationsSelect: "SET_SELECTED"
    }),

    eventsContestantsResultsInputDialogOpen(contestant): void {
      this.$emit("eventsContestantsResultsInputDialogOpen", contestant)
    },

    eventsContestantsResultsMeasurementOpen(contestant): void {
      this.eventsContestantsResultsMeasurementShown = true
      this.eventsContestantsMutationsSelect(contestant)
    },

    clubFilter(_, __, { clubMember }): boolean {
      return this.eventsContestantsTableFilter.clubIds.length
        ? this.eventsContestantsTableFilter.clubIds.includes(
          clubMember.clubId || "Ikke tildelt"
        )
        : true
    },

    weaponFilter(_, __, { weaponId }): boolean {
      return this.eventsContestantsTableFilter.weaponIds.length
        ? this.eventsContestantsTableFilter.weaponIds.includes(weaponId)
        : true
    },

    divisionFilter(_, __, { divisionId }): boolean {
      return this.eventsContestantsTableFilter.divisionIds.length
        ? this.eventsContestantsTableFilter
          .divisionIds
          .includes(divisionId || "Ikke tildelt")
        : true
    }
  }
}
