
import Vue from "vue"
import { mapState, mapActions } from "vuex"
import eventsStub from "./events.stub"
import DatePicker from "@/components/DatePicker.vue"
import EventsCategoriesCreateDialog
  from "./categories/EventsCategoriesCreateDialog.vue"
import EventsCategoriesEditDialog
  from "./categories/EventsCategoriesEditDialog.vue"
import Confirm from "@/components/Confirm.vue"

export default Vue.extend({
  name: "EventsForm",

  components: {
    DatePicker,
    EventsCategoriesCreateDialog,
    EventsCategoriesEditDialog,
    Confirm
  },

  props: {
    value: { type: Object, default: () => eventsStub }
  },

  data: () => ({
    eventsCategoriesCreateDialogShown: false,
    eventsCategoriesEditDialogShown: false,
    eventsCategoriesEditDialogEventsCategory: {}
  }),

  computed: {
    ...mapState("clubs", {
      clubsStateListIsLoading: "createIsLoading",
      clubsStateList: "list"
    }),

    ...mapState("ranges", {
      rangesStateListIsLoading: "listIsLoading",
      rangesStateList: "list"
    }),

    ...mapState("events/categories", {
      eventsCategoriesStateListIsLoading: "listIsLoading",
      eventsCategoriesStateList: "list",
      eventsCategoriesStateRemoveOneIsLoading: "removeOneIsLoading",
      eventsCategoriesStateRemoveManyIsLoading: "removeManyIsLoading"
    }),

    eventsCategoriesIsLoading(): boolean {
      return (
        this.eventsCategoriesStateRemoveOneIsLoading ||
        this.eventsCategoriesStateRemoveManyIsLoading ||
        this.eventsCategoriesStateListIsLoading
      )
    }
  },

  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(data): void {
        this.$emit("input", data)
      }
    }
  },

  created() {
    this.clubsActionsList()
    this.rangesActionsList()
    this.eventsCategoriesActionsList()
  },

  methods: {
    ...mapActions("clubs", {
      clubsActionsList: "list"
    }),

    ...mapActions("ranges", {
      rangesActionsList: "list"
    }),

    ...mapActions("events/categories", {
      eventsCategoriesActionsList: "list",
      eventsCategoriesActionsRemoveOne: "removeOne",
      eventsCategoriesActionsRemoveMany: "removeMany"
    }),

    submit(cb): void {
      this.$refs.localForm.validate()
        ? cb()
        : this.$error(this.$t("validationError"))
    },

    resetFields(): void {
      this.$refs.localForm.reset()
    },

    eventsCategoriesCreateDialogOpen(): void {
      this.eventsCategoriesCreateDialogShown = true
    },

    eventsCategoriesEditDialogOpen(eventsCategory): void {
      this.eventsCategoriesEditDialogShown = true
      this.eventsCategoriesEditDialogEventsCategory = eventsCategory
    },

    async eventsCategoriesRemoveOne(eventsCategory): Promise<void> {
      const eventsCategoryName = eventsCategory.name

      if(!await this.$refs.confirm.dangerous(
        this.$t("eventsCategoriesRemoveOneConfirmation", {
          eventsCategoryName
        })
      )) return

      try {
        await this.eventsCategoriesActionsRemoveOne(eventsCategory)
        this.$success(this.$t("eventsCategoriesActionsRemoveOneSuccess", {
          eventsCategoryName
        }))
      } catch(e) {
        this.$error(e.message)
      }
    },

    async eventsCategoriesRemoveMany(eventsCategories): Promise<void> {
      const count = eventsCategories.length

      if(!await this.$refs.confirm.dangerous(
        this.$t("eventsCategoriesRemoveManyConfirmation", {
          eventsCategoriesCount: count
        })
      )) return

      try {
        await this.eventsCategoriesActionsRemoveMany(eventsCategories)
        this.$success(this.$t("eventsCategoriesActionsRemoveManySuccess", {
          eventsCategoriesCount: count
        }))
      } catch(e) {
        this.$error(e.message)
      }
    },

    setCategory(category): void {
      this.value.categoryId = category.id
    }
  }
})
