
import Vue from "vue"
import { mapActions, mapState } from "vuex"
import EventsContestantsListTable
  from "./contestants/EventsContestantsListTable.vue"
import EventsContestantsCreateDialog
  from "./contestants/EventsContestantsCreateDialog.vue"
import EventsContestantsEditDialog
  from "./contestants/EventsContestantsEditDialog.vue"
import Confirm
  from "@/components/Confirm.vue"

export default Vue.extend({
  name: "EventsViewTabsContestants",

  components: {
    EventsContestantsListTable,
    EventsContestantsCreateDialog,
    EventsContestantsEditDialog,
    Confirm
  },

  props: {
    event: { type: Object, required: true }
  },

  data: () => ({
    eventsContestantsCreateDialogShow: false,
    eventsContestantsEditDialogShow: false,
    eventsContestantsEditContestant: {}
  }),

  computed: {
    ...mapState("events/contestants", {
      eventsContestantsStateRemoveOneIsLoading: "removeOneIsLoading",
      eventsContestantsStateRemoveManyIsLoading: "removeManyIsLoading"
    })
  },

  methods: {
    ...mapActions("events/contestants", {
      eventsContestantsActionsRemoveOne: "removeOne",
      eventsContestantsActionsRemoveMany: "removeMany"
    }),

    eventsContestantsCreateDialogOpen(): void {
      this.eventsContestantsCreateDialogShow = true
    },

    eventsContestantsEditDialogOpen(contestant): void {
      this.eventsContestantsEditDialogShow = true
      this.eventsContestantsEditContestant = contestant
    },

    async eventsContestantsRemoveOne(contestant): Promise<void> {
      const fullName = `${contestant.clubMember.firstName} ${contestant.clubMember.lastName}`

      if(!await this.$refs.confirm.dangerous(
        this.$t("eventsContestantsActionsRemoveOneConfirmation", {
          clubMemberFullName: fullName
        })
      )) return

      try {
        await this.eventsContestantsActionsRemoveOne(contestant)
        this.$success(this.$t("eventsContestantsActionsRemoveOneSuccess", {
          clubMemberFullName: fullName
        }))
      } catch(e) {
        this.$error(e.message)
      }
    },

    async eventsContestantsRemoveMany(contestants): Promise<void> {
      const count = contestants.length

      if(!await this.$refs.confirm.dangerous(
        this.$t("eventsContestantsActionsRemoveManyConfirmation", {
          contestantsCount: count
        })
      )) return

      try {
        await this.eventsContestantsActionsRemoveMany(contestants)
        this.$success(this.$t("eventsContestantsActionsRemoveManySuccess", {
          contestantsCount: count
        }))
      } catch(e) {
        this.$error(e.message)
      }
    }
  }
})
