
import _uniqueBy from "lodash.uniqby"
import Vue from "vue"
import { sortCollator } from "@/utils"
import TableFilterSelect
  from "@/components/TableFilterSelect.vue"

const _uniqueById = (array) => _uniqueBy(array, "id")

export default Vue.extend({
  name: "EventsContestantsFilterClubs",

  components: {
    TableFilterSelect
  },

  props: {
    value: { type: Array, required: true },
    contestants: { type: Array, required: true },
    loading: { type: Boolean, required: false }
  },

  data() {
    return {
      selection: []
    }
  },

  computed: {
    clubsMembers(): any {
      return _uniqueById(this.contestants.map(({ clubMember }) => clubMember))
    },
    clubs(): any {
      return _uniqueById(this.clubsMembers.map(({ club }) => club))
        .sort((a, b) => {
          return sortCollator.compare(a.name, b.name)
        })
    }
  },

  watch: {
    selection(v): void {
      this.$emit("input", v)
    }
  },

  created() {
    this.selection = this.value
  },

  methods: {
    clubName(club): string {
      return club && club.name
        ? `${club.name} (${club.shortName})`
        : "Ikke tildelt"
    }
  }
})
