
import Vue from "vue"
import { mapActions, mapState, mapGetters, mapMutations } from "vuex"
import Avatar from "@/components/Avatar.vue"
import EventsDivisionsSchedulerDivisionSelectItem
  from "./EventsDivisionsSchedulerDivisionSelectItem.vue"
import EventsContestantsNameLabel
  from "../contestants/EventsContestantsNameLabel.vue"
import EventsContestantsWeaponLabel
  from "../contestants/EventsContestantsWeaponLabel.vue"

export default Vue.extend({
  name: "EventsDivisionsScheduler",

  components: {
    Avatar,
    EventsDivisionsSchedulerDivisionSelectItem,
    EventsContestantsNameLabel,
    EventsContestantsWeaponLabel
  },

  data() {
    return {
      unAssignedSearchFilter: "",
      dragContestantId: null,
      dragType: "",
      isPrinting: false,
      mql: null
    }
  },

  computed: {
    ...mapState("events", {
      eventsStateSelectedIsLoading: "selectedIsLoading",
      eventsStateSelected: "selected"
    }),
    ...mapState("events/divisions", {
      eventsDivisionsStateListIsLoading: "listIsLoading",
      eventsDivisionsStateList: "list",
      eventsDivisionsStateSelectedIsLoading: "selectedIsLoading",
      eventsDivisionsStateSelected: "selected"
    }),
    ...mapState("events/contestants", {
      eventsContestantsStateListIsLoading: "listIsLoading",
      eventsContestantsStateList: "list",
      eventsContestantsStateEditOneIsLoading: "editOneIsLoading",
      eventsContestantsStateEditManyIsLoading: "editManyIsLoading"
    }),
    ...mapGetters("events/contestants", {
      getTimesByDivisionId: "timesByDivisionId",
      getStandsByDivisionId: "standsByDivisionId",
      getScheduleByDivisionId: "scheduleByDivisionId",
      getUnAssignedByWeaponDistance: "unAssignedByWeaponDistance",
      getUnAssigned: "unAssigned"
    }),

    isDragging(): boolean {
      return this.dragType !== ""
    },

    hasDivision(): boolean {
      return Object.keys(this.eventsDivisionsStateSelected).length > 1
    },

    // @TODO: handle last time 24:00
    // @TODO: handle minutes (ie. 10:00 + 10:30)
    times() {
      if(!this.eventsDivisionsStateSelected) return
      const times = this.getTimesByDivisionId(this.eventsDivisionsStateSelected.id)

      const { startsAt } = this.eventsDivisionsStateSelected
      let firstHour = parseInt(startsAt, 10)
      let lastHour = firstHour

      if(times.length) {
        const firstTime = times[0].split(":")
        const firstTimeHour = parseInt(firstTime[0], 10)
        if(firstTimeHour < firstHour) firstHour = firstTimeHour

        const lastTime = times[times.length - 1].split(":")
        lastHour = parseInt(lastTime[0], 10)
      }

      const withMissingHours = Array.from(
        { length: lastHour - firstHour + 1 },
        (v, k) => k + firstHour
      )

      const timeline = withMissingHours.reduce((acc, hour) => {
        const existingTime = times.find((timeString) => {
          return hour === parseInt(timeString.split(":")[0], 10)
        })
        if(existingTime) {
          acc.push(existingTime)
        } else {
          let newHour: string | number = hour
          if(newHour < 10) newHour = `0${newHour}`
          acc.push(`${newHour}:00`)
        }
        return acc
      }, [])

      if(this.isPrinting) return timeline

      let newLastHour: string | number = lastHour + 1
      if(newLastHour < 10) newLastHour = `0${newLastHour}`

      let newFirstHour: string | number = firstHour - 1
      if(newFirstHour < 10) newFirstHour = `0${newFirstHour}`

      if(newFirstHour !== "0-1") timeline.unshift(`${newFirstHour}:00`)
      if(newLastHour !== 24) timeline.push(`${newLastHour}:00`)

      return timeline
    },

    stands() {
      if(!this.eventsDivisionsStateSelected) return

      const stands = this.getStandsByDivisionId(
        this.eventsDivisionsStateSelected.id
      )
      const { standsCount } = this.eventsDivisionsStateSelected
      let lastStand = Math.max(...stands)
      if(lastStand < standsCount) lastStand = standsCount

      const scheduleStands = Array.from({ length: lastStand }, (v, k) => k + 1)
      return this.isPrinting
        ? scheduleStands
        : [
          ...scheduleStands,
          lastStand + 1
        ]
    },

    schedule() {
      if(!this.eventsDivisionsStateSelected) return
      return this.getScheduleByDivisionId(
        this.eventsDivisionsStateSelected.id
      )
    },

    unAssigned(): any {
      if(!this.eventsDivisionsStateSelected) return []
      return this.getUnAssignedByWeaponDistance(
        this.eventsDivisionsStateSelected.distance
      ).filter((item) => {
        if(
          !this.unAssignedSearchFilter ||
          this.unAssignedSearchFilter === ""
        ) {
          return true
        }
        return JSON.stringify(item)
          .toLowerCase()
          .includes(this.unAssignedSearchFilter.toLowerCase())
      })
    },

    hasSchedule(): boolean {
      if(!this.times || !this.stands) return false
      return this.times.length > 0 && this.stands.length > 0
    }
  },

  watch: {
    stands: "resizeColumn"
  },

  created() {
    this.mql = window.matchMedia("print")
    this.mql.addListener(this.onPrinting)
    const divisions = this.eventsDivisionsStateList
    if(!divisions.length) return
    this.eventsDivisionsActionsSelect({ id: divisions[0].id })
  },

  beforeDestroy(): void {
    this.eventsDivisionsMutationsSetSelected({})
    this.mql.removeListener(this.onPrinting)
  },

  methods: {
    onPrinting(e: MediaQueryList | MediaQueryListEvent): void {
      this.isPrinting = e.matches
      this._update(this._render())
      this.setGridTemplateColumns()
    },

    ...mapActions("events/divisions", {
      eventsDivisionsActionsSelect: "select"
    }),
    ...mapMutations("events/divisions", {
      eventsDivisionsMutationsSetSelected: "SET_SELECTED"
    }),
    ...mapActions("events/contestants", {
      eventsContestantsActionsEditOne: "editOne",
      eventsContestantsActionsEditMany: "editMany"
    }),

    changeDivision(id): void {
      this.eventsDivisionsActionsSelect({ id })
    },

    setGridTemplateColumns(): void {
      this.$refs.grid.style.gridTemplateColumns = "1fr "
        .repeat(this.stands.length)
        .slice(0, -1)
    },

    resizeColumn(): void {
      if(!this.hasDivision) return
      const stands = this.stands
      if(!stands) return
      this.$nextTick(() => {
        this.setGridTemplateColumns()
      })
    },

    addDragOverClass(e: DragEvent): void {
      const cell = e.target as HTMLElement
      if(cell) cell.classList.add("dragover")
    },

    removeDragOverClass(e: DragEvent): void {
      const cell = e.target as HTMLElement
      cell.classList.remove("dragover")
    },

    dragOver(e: DragEvent): void {
      e.preventDefault()
      e.stopPropagation()
      e.dataTransfer.dropEffect = "move"
      this.addDragOverClass(e)
    },

    dragEnd(e: DragEvent): void {
      this.dragContestantId = null
      this.dragType = ""
      this.removeDragOverClass(e)
    },

    dragStartUnAssigned(e): void {
      this.dragType = "unAssigned"
      const data = e.target.dataset
      this.dragContestantId = data.contestantid
    },

    dragStartAssigned(e): void {
      this.dragType = "assigned"
      const data = e.target.dataset
      e.dataTransfer.setData("time", data.time)
      e.dataTransfer.setData("stand", data.stand)
      e.dataTransfer.effectAllowed = "move"
      this.dragContestantId = data.contestantid
    },

    dropAddOrUpdate(e: DragEvent): void {
      const cell = e.target as HTMLElement
      if(!cell) return
      const toTime = cell.dataset.time
      const toStand = cell.dataset.stand
      this.eventsContestantsActionsEditOne({
        ...this.eventsContestantsStateList.find(
          ({ id }) => this.dragContestantId === id
        ),
        time: toTime,
        stand: parseInt(toStand, 10),
        divisionId: this.eventsDivisionsStateSelected.id
      })
    },

    dropSwap(e: DragEvent): void {
      const cell = e.target as HTMLElement
      if(!cell) return
      const toTime = cell.dataset.time
      const toStand = cell.dataset.stand
      const contestants = []

      if(this.dragType === "unAssigned") {
        const dragContestant = this.eventsContestantsStateList
          .find((c) => this.dragContestantId === c.id)
        contestants.push({
          ...dragContestant,
          time: toTime,
          stand: parseInt(toStand, 10),
          divisionId: this.eventsDivisionsStateSelected.id
        })

        const dropOnContestant = this.schedule[toTime][toStand]
        contestants.push({
          ...dropOnContestant,
          time: undefined,
          stand: undefined,
          divisionId: undefined,
          division: undefined
        })
      }

      if(this.dragType === "assigned") {
        const fromTime = e.dataTransfer.getData("time")
        const fromStand = e.dataTransfer.getData("stand")
        if(fromTime === toTime && fromStand === toStand) return

        const dragContestant = this.schedule[fromTime][fromStand]
        contestants.push({
          ...dragContestant,
          time: toTime,
          stand: parseInt(toStand, 10),
          divisionId: this.eventsDivisionsStateSelected.id
        })

        if(this.schedule[toTime] && this.schedule[toTime][toStand]) {
          const dropOnContestant = this.schedule[toTime][toStand]
          contestants.push({
            ...dropOnContestant,
            time: fromTime,
            stand: parseInt(fromStand, 10),
            divisionId: this.eventsDivisionsStateSelected.id
          })
        }
      }

      this.eventsContestantsActionsEditMany(contestants)
    },

    dropUnAssign(): void {
      const dropOnContestant = this.eventsContestantsStateList.find(
        (c) => this.dragContestantId === c.id
      )
      this.eventsContestantsActionsEditMany([{
        ...dropOnContestant,
        time: undefined,
        stand: undefined,
        divisionId: undefined,
        division: undefined
      }])
    },

    eventsDivisionsCreateDialogOpen(): void {
      this.$emit("eventsDivisionsCreateDialogOpen")
    },

    eventsDivisionsEditDialogOpen(division): void {
      this.$emit("eventsDivisionsEditDialogOpen", division)
    },

    eventsDivisionsRemoveOne(division): void {
      this.$emit("eventsDivisionsRemoveOne", division)
    },

    eventsContestantsCreateDialogOpen(time: string, stand: string): void {
      this.$emit("eventsContestantsCreateDialogOpen", { time, stand })
    },

    eventsContestantsEditDialogOpen(contestant): void {
      this.$emit("eventsContestantsEditDialogOpen", contestant)
    }
  }
})
