
import Vue from "vue"

export default Vue.extend({
  name: "TableFilterSelect",
  props: {
    value: { type: Array, required: true },
    items: { type: Array, required: true },
    dataTestid: { type: String, required: false, default: "" }
  },
  data() {
    return {
      selection: []
    }
  },
  watch: {
    selection(v): void {
      this.$emit("input", v)
    }
  },
  created() {
    this.selection = this.value
  }
})
