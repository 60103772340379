
import Vue from "vue"
import { mapActions, mapState } from "vuex"
import ActionDialog from "@/components/ActionDialog.vue"

export default Vue.extend({
  name: "EventsContestantsMeasurements",

  components: {
    ActionDialog
  },

  props: {
    shown: { type: Boolean, default: false }
  },

  data() {
    return {
      visible: this.shown,
      measurement: 0
    }
  },

  computed: {
    ...mapState("events/contestants", {
      eventsContestantsEditOneIsLoading: "editOneIsLoading",
      eventsContestantsStateSelected: "selected"
    }),
    hasContestant(): boolean {
      return Object.keys(this.eventsContestantsStateSelected).length > 1
    }
  },

  watch: {
    visible(visible): void {
      this.$emit("update:shown", visible)
    },
    shown(visible): void {
      this.measurement = this.eventsContestantsStateSelected.measurement
      this.visible = visible
    }
  },

  methods: {
    ...mapActions("events/contestants", {
      eventsContestantsActionsEditOne: "editOne"
    }),
    async submit(measurement: string): Promise<void> {
      await this.eventsContestantsActionsEditOne({
        ...this.eventsContestantsStateSelected,
        measurement: parseInt(measurement) || 0
      })
      this.close()
    },
    close(): void {
      this.visible = false
    }
  }
})
