
import Vue from "vue"
import EventsContestantsResultsListTable
  from "./contestants/EventsContestantsResultsListTable.vue"
import EventsContestantsResultsInputDialog
  from "./contestants/EventsContestantsResultsInputDialog.vue"

export default Vue.extend({
  name: "EventsViewTabsResults",

  components: {
    EventsContestantsResultsListTable,
    EventsContestantsResultsInputDialog
  },

  props: {
    event: { type: Object, required: true }
  },

  data: () => ({
    eventsContestantsResultsInputDialogShow: false,
    eventsContestantsResultsInputDialogContestant: {}
  }),

  methods: {
    eventsContestantsResultsInputDialogOpen(contestant): void {
      this.eventsContestantsResultsInputDialogShow = true
      this.eventsContestantsResultsInputDialogContestant = contestant
    }
  }
})
