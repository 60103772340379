
import Vue from "vue"
import TableFilterSearch from "@/components/TableFilterSearch.vue"

export default Vue.extend({
  name: "EventsListTable",

  components: {
    TableFilterSearch
  },

  props: {
    events: { type: Array, required: true },
    loading: { type: Boolean, default: false }
  },

  data() {
    return {
      selection: [],
      search: "",
      headers: [{
        value: "title",
        text: this.$t("columnTitleApprobatedLabel")
      }, {
        value: "startsAt",
        text: this.$t("columnStartsAtLabel")
      }, {
        value: "rangeId",
        text: this.$t("columnRangeNameLabel")
      }, {
        value: "organizerId",
        text: this.$t("columnClubNameLabel")
      }, {
        value: "categoryId",
        text: this.$t("columnCategoryNameLabel")
      }, {
        value: "actions",
        sortable: false,
        align: "right"
      }]
    }
  },

  methods: {
    eventsCreateDialogOpen(): void {
      this.$emit("eventsCreateDialogOpen")
    },

    eventsEditDialogOpen(event): void {
      this.$emit("eventsEditDialogOpen", event)
    },

    eventsRemoveOne(event): void {
      this.$emit("eventsRemoveOne", event)
    },

    eventsRemoveMany(events): void {
      this.$emit("eventsRemoveMany", events)
    },

    click(event): void {
      this.$router.push(`/events/${event.id}`)
    }
  }
})
