
import Vue from "vue"
import eventsCategoriesStub from "./events.categories.stub"

export default Vue.extend({
  name: "EventsCategoriesForm",

  props: {
    value: { type: Object, default: () => eventsCategoriesStub }
  },

  watch: {
    value: {
      deep: true,
      handler(data): void {
        this.$emit("input", data)
      }
    }
  },

  methods: {
    submit(cb): void {
      this.$refs.localForm.validate()
        ? cb()
        : this.$error(this.$t("validationError"))
    },

    resetFields(): void {
      this.$refs.localForm.reset()
    }
  }
})
