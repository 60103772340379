
import Vue from "vue"
import { mapState, mapActions } from "vuex"
import eventsContestantsStub from "./events.contestants.stub"

export default Vue.extend({
  name: "EventsContestantsForm",

  props: {
    value: { type: Object, default: () => eventsContestantsStub }
  },

  computed: {
    ...mapState("weapons", {
      weaponsStateListIsLoading: "listIsLoading",
      weaponsStateList: "list"
    }),
    // ...mapState("events/divisions", {
    //   eventsDivisionsStateListIsLoading: "listIsLoading",
    //   eventsDivisionsStateList: "list"
    // })
  },

  watch: {
    value: {
      deep: true,
      handler(data): void {
        this.$emit("input", data)
      }
    }
  },

  created() {
    this.weaponsActionsList({
      filter: {}, options: { limit: false }
    })
    // this.eventsDivisionsActionsList({
    //   filter: { eventId: this.value.eventId }
    // })
  },

  methods: {
    ...mapActions("weapons", {
      weaponsActionsList: "list"
    }),

    // ...mapActions("events/divisions", {
    //   eventsDivisionsActionsList: "list"
    // }),

    submit(cb): void {
      this.$refs.localForm.validate()
        ? cb()
        : this.$error(this.$t("validationError"))
    },

    resetFields(): void {
      this.$refs.localForm.reset()
    },

    // formatDivision({ day, time, distance }): string {
    //   return `${this.$date(day).format("ddd, DD/MMM")} - ${distance} meter`
    // }
  }
})
