
import { mapState } from "vuex"
import Avatar from "@/components/Avatar.vue"
import EventsContestantsFilterDivisions
  from "./EventsContestantsFilterDivisions.vue"
import EventsContestantsFilterClubs
  from "./EventsContestantsFilterClubs.vue"
import EventsContestantsFilterWeapons
  from "./EventsContestantsFilterWeapons.vue"
import EventsContestantsFilterMembers
  from "./EventsContestantsFilterMembers.vue"
import EventsDivisionsLabel
  from "../divisions/EventsDivisionsLabel.vue"

export default {
  name: "EventsContestantsListTable",

  components: {
    Avatar,
    EventsContestantsFilterDivisions,
    EventsContestantsFilterClubs,
    EventsContestantsFilterWeapons,
    EventsContestantsFilterMembers,
    EventsDivisionsLabel
  },

  props: {
    event: { type: Object, required: true }
  },

  data() {
    return {
      eventsContestantsTableFilter: {
        clubMemberIds: [],
        weaponIds: [],
        clubIds: [],
        divisionIds: []
      },
      eventsContestantsSearchFilter: "",
      eventsContestantsTableGroupBy: [],
      eventsContestantsSelection: [],
      eventsContestantsHeaders: [{
        value: "number",
        text: this.$t("columnLabelClubMember"),
        filter: this.clubMemberFilter
      }, {
        value: "clubMember.club.shortName",
        text: this.$t("columnLabelClub"),
        filter: this.clubFilter
      }, {
        value: "weaponId",
        text: this.$t("columnLabelWeapon"),
        filter: this.weaponFilter
      }, {
        value: "calibre",
        text: this.$t("columnLabelCalibre")
      }, {
        value: "divisionId",
        text: this.$t("columnLabelDivision"),
        filter: this.divisionFilter
      }, {
        value: "stand",
        text: this.$t("columnLabelStand")
      }, {
        value: "time",
        text: this.$t("columnLabelTime")
      }, {
        value: "actions",
        sortable: false,
        align: "right"
      }]
    }
  },

  computed: {
    ...mapState("events/contestants", {
      eventsContestantsStateListIsLoading: "listIsLoading",
      eventsContestantsStateList: "list"
    }),

    isntGrouped(): boolean {
      return this.eventsContestantsTableGroupBy === null
    }
  },

  methods: {
    clubMemberFilter(_, __, { clubMemberId }): boolean {
      return this.eventsContestantsTableFilter.clubMemberIds.length
        ? this.eventsContestantsTableFilter.clubMemberIds.includes(clubMemberId)
        : true
    },

    clubFilter(_, __, { clubMember }): boolean {
      return this.eventsContestantsTableFilter.clubIds.length
        ? this.eventsContestantsTableFilter.clubIds.includes(
          clubMember.clubId || "Ikke tildelt"
        )
        : true
    },

    weaponFilter(_, __, { weaponId }): boolean {
      return this.eventsContestantsTableFilter.weaponIds.length
        ? this.eventsContestantsTableFilter.weaponIds.includes(weaponId)
        : true
    },

    divisionFilter(_, __, { divisionId }): boolean {
      return this.eventsContestantsTableFilter.divisionIds.length
        ? this.eventsContestantsTableFilter.divisionIds.includes(
          divisionId || "Ikke tildelt"
        )
        : true
    },

    eventsContestantsCreateDialogOpen(): void {
      this.$emit("eventsContestantsCreateDialogOpen")
    },

    eventsContestantsEditDialogOpen(contestant): void {
      this.$emit("eventsContestantsEditDialogOpen", contestant)
    },

    eventsContestantsRemoveOne(contestant): void {
      this.$emit("eventsContestantsRemoveOne", contestant)
    },

    eventsContestantsRemoveMany(contestants): void {
      this.$emit("eventsContestantsRemoveMany", contestants)
    }
  }
}
