
import Vue from "vue"
import _cloneDeep from "lodash.clonedeep"
import _range from "lodash.range"

const generateHits = () => _range(1, 14)
  .map((i) => ({ hit: i, sum: null }))

export default Vue.extend({
  name: "ResultsFormButtons",

  props: {
    value: {
      type: Object, default: (): any => ({ hits: generateHits(), total: 0 })
    }
  },

  data: () => ({
    hits: generateHits(),
    currentHit: 1,
    currentSum: 0
  }),

  computed: {
    total(): number {
      let hits = this.hits.filter((hit) => {
        return Number.isInteger(hit.sum)
      })

      if(hits.length < 10) return undefined

      hits = hits.sort((a, b) => {
        if(a.sum < b.sum) return -1
        if(a.sum > b.sum) return 1
        return 0
      })

      if(hits.length > 10) hits = hits.slice(3)

      return hits
        .reduce((acc, c) => acc + c.sum, 0)
    }
  },

  watch: {
    value: {
      immediate: true,
      handler({ hits }): void {
        if(!hits.length) {
          this.hits = generateHits()
          return
        }
        if(hits !== this.hits) {
          this.hits = _cloneDeep(hits)
        }
      }
    },
    hits: {
      deep: true,
      handler(hits): void {
        this.$emit("input", { hits, total: this.total })
      }
    }
  },

  mounted() {
    document.addEventListener("keyup", this.onKeyUp)
  },

  beforeDestroy() {
    document.removeEventListener("keyup", this.onKeyUp)
  },

  methods: {
    onKeyUp(e): void {
      if(![
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown"
      ].includes(e.key)) return

      if(e.key === "ArrowLeft") {
        if(this.currentHit === 1) return
        this.currentHit--
      }

      if(e.key === "ArrowRight") {
        if(this.currentHit === 13) return
        this.currentHit++
      }

      if(e.key === "ArrowUp") {
        if(this.currentSum === 0) return
        this.currentSum--
      }

      if(e.key === "ArrowDown") {
        if(this.currentSum === 10) return
        this.currentSum++
      }

      this.setHit(this.currentHit, this.currentSum)
    },

    getHit(hit) {
      const i = this.hits.findIndex((h) => h.hit === hit)
      return this.hits[i]
    },

    isSet(hit, sum): boolean {
      return this.getHit(hit).sum === sum
    },

    setHit(hit, sum): void {
      this.getHit(hit).sum = sum
    },

    _range,

    clear(): void {
      this.hits = generateHits()
    }
  }
})
