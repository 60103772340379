
import Vue from "vue"

export default Vue.extend({
  name: "DatePicker",

  props: {
    value: { type: String, default: (): string => "" },
    label: { type: String, required: true },
    dataTestid: { type: String, default: (): string => "datepicker-input" },
    rules: { type: Array, default: (): boolean => false },
    format: { type: String, default: (): string => "DD-MMM-YY" },
    className: { type: String, default: (): string => "" },
    dense: { type: Boolean, default: false, required: false }
  },

  data: () => ({
    datePickerMenuShown: false,
    v: ""
  }),

  computed: {
    input(): string {
      return this.value !== ""
        ? this.$date(this.value).format(this.format)
        : ""
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(data): void {
        if(data !== this.v) this.v = data
      }
    },

    v(v): void {
      this.$emit("input", v)
    }
  }
})
